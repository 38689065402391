import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import { Articles } from "../components/ArticlePage/others"
import { NavContainer } from "../components/BrandPage/brand-page.css"
import { Container, Title } from "../components/Hero/hero.css"
import Newsletter from "../components/Newsletter/newsletter"
import BreadCrumb from "../components/Breadcrumb/breadcrumb"

import {
  ArticlesContainer,
} from "../components/ArticlePage/others.css"


const Brands = ({data}) => {
  const models = data.allBuilderModels
  const page = models.page[0].data
  const articles = models.article
  const brands = models.brand
  return <>
  <Helmet>
    <title>{page.title}</title>
    <meta property="og:url" content={`https://www.watchesguild.com/brands/`} />
    <meta property="og:description" content={page.description} />
    <meta name="description" content={page.description} />
    <link rel="canonical" href={`https://www.watchesguild.com/brands/`}/>
    <script type="application/ld+json">
      {`
      {"@context":"http://schema.org","@type":"BreadcrumbList","itemListElement":[
        {"@type":"ListItem","position":1,"item":{"@id":"https://www.watchesguild.com/brands","name":"Brands"}},
      ]}
      `}
    </script>
  </Helmet>
  <main
    style={{
      margin: `0 auto`,
    }} className="brandsPage stdPage"
  >
    <div class="heroSection">
      <div class="heroContainer">
        <BreadCrumb pageName="Brands" />
        <Container className="imageContainer container">
          <Title>{page.title}</Title>
          <p>{page.description}</p>
        </Container>
      </div>
    </div>
    <nav class="heroNav">
      <div class="container">
        <NavContainer>
        {brands.map((brand, index) => (
          <li key={index} >
            <a href={`/brands/${brand.data.name.toLowerCase().replace(/\s/g, "")}`}>{brand.data.name}</a>
          </li>
        ))}
        </NavContainer>
      </div>
    </nav>
    <div class="container">
      <ArticlesContainer className="brandArticles Articles">
        <Articles articles={articles} />
      </ArticlesContainer>
    </div>
    <Newsletter placeholder="Your email here" submit="Subscribe" />
  </main>
</>
};

export default Brands

export const pageQuery = graphql`
  query {
    allBuilderModels {
      page(
        target: { urlPath: "/brands/" }
        limit: 1
        options: { cachebust: true }
      ) {
        data {
          title
          description
          template
          url
        }
      }
      brand(limit: 12, query: {data: {featuredOnLanding: true}} , sort: {data: {rankOnLanding: 1}}) {
        data {
          name
          description
          image
          featured
          rank
        }
      }
      article(limit: 9, sort: {createdDate: -1}) {
        data {
          title
          slug
          summary
          picture
          writer
        }
        createdDate
      }
    }
  }
`;